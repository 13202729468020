import axios from '@axios'

const PREFIX = 'portal-documentos'

export default {

  getPaginado(page, pageSize, filtros, filtroString) {
    return axios.post(`${PREFIX}/paged?pagina=${page}&tamanhoPagina=${pageSize}&multiFiltro=${filtroString}`, filtros)
  },

  verificarNome(nome) {
    return axios.get(`${PREFIX}/verificar-nome?nome=${nome}`)
  },

  getFiltroCustom() {
    return axios.get(`${PREFIX}/filtros-custom`)
  },

  salvarFiltroCustom(filtro) {
    return axios.post(`${PREFIX}/filtro?`, filtro)
  },

  deleteFiltroCustom(filtroId) {
    return axios.delete(`${PREFIX}/filtro?filtroId=${filtroId}`)
  },

  getUsuariosFiltro() {
    return axios.get(`${PREFIX}/usuarios-filtro`)
  },

  getEmpresasFiltro() {
    return axios.get(`${PREFIX}/empresas`)
  },

  baixaEmLote(nome, filtroDocs) {
    return axios.post(`${PREFIX}/baixa-lote?nomeArquivo=${nome}`, filtroDocs)
  },

  enviarEmLote(nome, filtroDocs) {
    return axios.post(`${PREFIX}/enviar-email?nomeArquivo=${nome}`, filtroDocs)
  },

  salvarEmail(empresaId, email) {
    return axios.post(`${PREFIX}/email?id=${empresaId}&email=${email}`)
  },

  getObservacoes(id) {
    return axios.get(`${PREFIX}/${id}/observacao`)
  },

  removerObservacao(id) {
    return axios.delete(`${PREFIX}/observacao/${id}/remover`)
  },

  salvarObservacao(observacao) {
    return axios.post(`${PREFIX}/observacao/salvar`, observacao)
  },
}
