<template>
  <b-card>
    <portal-documentos-list
      ref="portalDocumentosList"
      :botoes-opcoes="botoesOption"
      :visualizacao-opcoes="visualizacao"
      :filtros="filtros"
      :filtros-rapidos="filtrosRapidos"
      :tem-filtro-extra="true"
      :tem-filtro-rapido="false"
      :show-botao-custom="true"
    >
      <template slot="show-botao-custom">
        <b-row>
          <b-col cols="5">
            <b-btn
              variant="none"
              class="btn btn-outline-primary"
              size="sm"
              style="white-space: nowrap; padding: 0.5495rem 1rem;"
              @click="enviarEmailLoteGeral()"
            >
              <feather-icon
                icon="SendIcon"
                class="text-primary"
                size="15"
              />
              Enviar por e-mail
            </b-btn>
          </b-col>
          <b-col cols="7">
            <v-select
              id="baixaPortalSelect"
              :ref="`SelectFiltroDocs`"
              v-model="tipoDownload"
              v-b-tooltip.hover
              :options="opcoesBaixaEmLote"
              :clearable="true"
              label="nome"
              placeholder="Baixar documentos"
              style="font-size: 11px; width: 230px;"
              @input="baixaEmLoteGeral()"
            />
          </b-col>
        </b-row>
      </template>
      <template slot="filtro-extra">
        <b-row>
          <b-col cols="6">
            <v-select
              :ref="`SelectFiltroDocs`"
              v-model="filtroString"
              v-b-tooltip.hover
              :options="filtrosRapidosDocs"
              :clearable="true"
              multiple
              deselect-from-dropdown
              :disabled="filtroCustom != null"
              placeholder="Tipos de documentos"
              @input="mudarDocSelecionado($event)"
            />
          </b-col>

          <!-- Filtro Custom -->
          <b-col
            cols="5"
            style="padding-right: 10px;"
          >
            <v-select
              :ref="`SelectFiltroCustom`"
              v-model="filtroCustom"
              v-b-tooltip.hover
              class="selectFiltro"
              :options="filtrosRapidos"
              :clearable="true"
              label="nome"
              placeholder="Filtros"
              @input="executarFiltro()"
            >
              <!-- <template #option="{ label }">            Pega prop label do objeto-->
              <template
                #option="{ nome, cor }"
              >
                <b-badge
                  pill
                  :style="`background-color:${cor};`"
                >
                  <!-- variant="light-primary" -->
                  {{ nome }}
                </b-badge>
              </template>
            </v-select>
          </b-col>
          <b-col
            cols="1"
            style="padding-left: 0px;display: flex;align-items: center;"
          >
            <div @click="abrirEditFiltro()">
              <feather-icon
                icon="EditIcon"
                class="text-primary"
                size="15"
              />
            </div>
          </b-col>
        </b-row>
      </template>
      <template
        slot="acoes"
        slot-scope="{ props} "
      >
        <acoes
          :ref="`acoes${props.row.id}`"
          :grid-props="props"
          :api="apiPortal"
          @baixa-em-lote="baixaEmLoteLinha"
          @enviar-email="enviarEmailLinha($event.linha, $event.email)"
        />
      </template>
    </portal-documentos-list>
    <filtro-custom
      ref="filtroCustomModal"
      v-model="filtroCustomEdit"
      :filtros-default-docs="filtrosDefaultDocs"
      :opcoes-filtros-custom="filtrosRapidos"
      @cancelar="carregarFiltro()"
    />
    <loading
      :show-cancel-button="false"
      :is-loading="isLoading"
      :descricao="'Enviando'"
    />
    <empresas-sem-email
      ref="empresaSemEmailList"
      v-model="empresasEmail"
      @salvar-email="salvarEmail($event)"
    />
  </b-card>
</template>

<style lang="scss">
.col-valores{
  white-space: nowrap;
}

[dir] #baixaPortalSelect .vs__dropdown-toggle{
  border-color: var(--primary) !important;
}
</style>

<script>

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import api from './shared/services/api'
import '@core/scss/vue/libs/vue-select.scss'

export default {
  name: 'PortalDocumentosView',
  components: {
    vSelect,
    PortalDocumentosList: () => import('@pilar/components/visualizacao-base/VisualizacaoBase.vue'),
    Acoes: () => import('./shared/components/acoes/Acoes.vue'),
    FiltroCustom: () => import('./shared/components/filtro-custom/FiltroCustom.vue'),
    Loading: () => import('@pilar/components/loading/Loading.vue'),
    EmpresasSemEmail: () => import('./shared/components/empresas-sem-email/EmpresasSemEmail.vue'),
  },
  data() {
    return {
      apiPortal: api,
      botoesOption: null,
      filtroCustomEdit: {},
      linha: null,
      visualizacao: {
        placeholderBusca: 'Buscar por: CNPJ/CPF, Razão social',
        acaoClass: 'col-td-acao-2',
        disabledCheckbox: false,
        colunas: [
          { label: 'Razão social', field: 'razaoSocial', tdClass: 'col-tamanho-cnpj' },
          { label: 'CNPJ/CPF', field: 'cadastroFederal', tdClass: 'col-tamanho-cnpj' },
        ],
      },
      filtros: [{ colunas: ['CadastroFederalSemMascara', 'CadastroFederal', 'RazaoSocial'], valor: null }],
      filtrosDefaultDocs: ['Todos', 'Situação Fiscal', 'CND RFB/PGFN', 'CND FGTS', 'CND Trabalhista', 'CND MTE', 'CND Estadual', 'CND Municipal'],
      filtrosRapidosDocs: [],
      filtrosRapidos: [],
      filtroString: [],
      filtroCustom: null,
      criando: true,
      aplicando: false,
      filtroAtual: {},
      tipoDownload: null,
      opcoesBaixaEmLote: ['Arquivo Único', 'Por Empresa', 'Tipo de documento', 'Empresa e Tipo de documento'],
      isLoading: false,
      empresasEmail: [],
    }
  },
  created() {
    this.visualizacao.evento = this.carregar
    this.tipoParcelamento = this.$route.meta.tipo
    this.filtrosRapidosDocs = this.filtrosDefaultDocs
    this.carregarFiltro()
  },
  methods: {
    mudarDocSelecionado(docs) {
      if (docs && docs.find(find => find === 'Todos')) {
        this.filtrosRapidosDocs = ['Todos']
      } else {
        this.filtrosRapidosDocs = this.filtrosDefaultDocs
      }
    },
    carregar(pagina, tamanhoPagina, filtro) {
      this.filtroAtual = filtro
      if (this.filtroCustom !== undefined && this.filtroCustom !== null) {
        return this.pegarEmpresasFiltro(pagina)
      }
      return api.getPaginado(pagina, tamanhoPagina, filtro, this.filtroString)
    },
    carregarFiltro() {
      const updateGrid = this.filtroCustom != null
      this.filtroCustom = null
      api.getFiltroCustom()
        .then(payload => {
          this.filtrosRapidos = payload.data
          if (updateGrid) {
            this.aplicarFiltro(null)
          }
        }).catch(err => {
          this.$erro('Erro ao carregar os filtros', 'danger')
          throw err
        })
    },
    pegarEmpresasFiltro(paginaAtual) {
      return new Promise(resolve => {
        resolve({
          data: {
            pagina: paginaAtual,
            total: this.filtroCustom.empresas.vinculadas.lenght,
            tamanhoPagina: 50,
            valores: this.filtroCustom.empresas.vinculadas,
          },
        })
      })
    },
    aplicarFiltro(filtro) {
      this.$refs.portalDocumentosList.aplicarFiltroRapido(filtro)
    },
    getColorTag(situacao) {
      if (situacao === 'EmAndamento') {
        return 'light-primary'
      }
      if (situacao === 'Ativa') {
        return 'light-success'
      }
      if (situacao === 'Retificada') {
        return 'light-warning'
      }
      if (situacao === 'Excluida') {
        return 'light-danger'
      }
      return 'light-secondary'
    },
    executarFiltro() {
      this.filtroString = []
      this.$refs.portalDocumentosList.montarGrid(this.$refs.portalDocumentosList.enviarServidor) // 400
    },
    abrirEditFiltro() {
      this.filtroCustomEdit = this.filtroCustom
      this.$refs.filtroCustomModal.show()
    },
    baixaEmLoteGeral() {
      const filtroDocs = this.filtroCustom ? this.filtroCustom.filtrosDocs : this.filtroString
      let empresas = this.$refs.portalDocumentosList.enviarServidor.linhasSelecionadas

      if (!empresas) {
        empresas = this.$refs.portalDocumentosList.gridInfo.dados
      }
      api.baixaEmLote('Portal Documentos', {
        Agrupamento: this.tipoDownload,
        Docs: filtroDocs,
        Empresas: empresas.map(x => x.id),
        Filtro: this.filtroAtual,
        ValidarFiltroCustom: this.filtroCustom != null && this.filtroCustom.some(),
      }) // [16, 2]
      this.tipoDownload = null
    },
    enviarEmailLoteGeral() {
      const filtro = this.filtroCustom ? this.filtroCustom.filtrosDocs : this.filtroString

      if (!filtro || !filtro.length > 0) {
        this.$erro('Insira ao menos um Documento', 'danger')
      } else {
        let empresas = this.$refs.portalDocumentosList.enviarServidor.linhasSelecionadas

        this.isLoading = true
        if (!empresas) {
          empresas = this.$refs.portalDocumentosList.gridInfo.dados
        }
        const empresaEmail = empresas.map(empresa => ({ empresaId: empresa.id, emailsCC: null, email: empresa.email ? empresa.email : null }))

        api.enviarEmLote('Portal Documentos', {
          docs: filtro,
          empresasEmail: [...empresaEmail],
        }).then(payload => {
          this.isLoading = false
          if (payload.data.length > 0) {
            this.empresasEmail = payload.data

            this.$refs.empresaSemEmailList.show()
          }
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'E-mails enviados com sucesso',
              icon: 'fa-solid fa-circle-check',
              variant: 'success',
            },
          })
        }).catch(err => {
          this.isLoading = false
          this.$erro('Erro ao enviar os e-mails', 'danger')
          throw err
        })
      }
    },
    enviarEmailLinha(linha, email) {
      const filtro = this.filtroCustom ? this.filtroCustom.filtrosDocs : this.filtroString
      if (!filtro || !filtro.length > 0) {
        this.$erro('Insira ao menos um Documento', 'danger')
      } else {
        this.isLoading = true

        const empresaEmail = {
          empresaId: linha.id,
          emailAdicionalEmpresa: email.emailsCc,
          email: email.emailsPara,
          mensagensAdicional: email.mensagensAdicional,
          definirEmailPadrao: email.definirEmailPadroes,
        }

        api.enviarEmLote('Portal Documentos', {
          docs: filtro,
          empresasEmail: [empresaEmail],
        }).then(() => {
          this.isLoading = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'E-mail enviado com sucesso',
              icon: 'fa-solid fa-circle-check',
              variant: 'success',
            },
          })
        }).catch(err => {
          this.isLoading = false
          this.$erro('Erro ao enviar os e-mails', 'danger')
          throw err
        })
      }
    },
    baixaEmLoteLinha(empresaId) {
      const filtroDocs = this.filtroCustom ? this.filtroCustom.filtrosDocs : this.filtroString
      api.baixaEmLote('Portal Documentos', { Docs: filtroDocs, Empresas: [empresaId] })
    },
    salvarEmail(empresa) {
      this.$refs.portalDocumentosList.gridInfo.dados.find(find => find.id === empresa.id).email = empresa.email
      api.salvarEmail(empresa.id, empresa.email)
        .then(() => {
          this.enviarEmailLinha(empresa, { emailsPara: [empresa.email], emailsCc: [] })
        })
    },
  },

}
</script>
